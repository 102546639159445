// extracted by mini-css-extract-plugin
export var bold = "Th";
export var info__item = "Mh";
export var info__list = "Kh";
export var info__work = "Lh";
export var item__icon = "Rh";
export var item__label = "Sh";
export var label = "Ih";
export var main = "Gh";
export var main__info = "Jh";
export var main__primary = "Hh";
export var price = "Oh";
export var salary = "Ph";
export var schedule = "Nh";
export var type = "Qh";